<template>
  <div class="auth-wrapper auth-v2">
    <div class="row auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Brandstock</h2>
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <div class="col-sm-8 d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/pages/register-v2.svg')" alt="Register V2" />
        </div>
      </div>
      <!-- /Left Text-->
      <!-- Register-->
      <div class="col-12 col-lg-4 d-flex align-items-center auth-bg px-2 p-lg-5">
        <div class="col col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
          <b-card-title class="mb-1">Всё начинается тут 🚀</b-card-title>
          <b-card-text class="mb-2">Сделай свой заработок легким!</b-card-text>
          <!-- form -->
          <div class="auth-register-form mt-2">
            <!-- username -->
            <div class="form-group">
              <label class="d-block">Имя</label>
              <input
                v-model="form.name"
                placeholder="Введите свое имя"
                :class="['form-control', submitted && !form.name ? 'is-invalid' : null]"
              >
              <small v-if="submitted && !form.name" class="text-danger">Поле не может быть пустым</small>
            </div>
            <div class="form-group">
              <label class="d-block">Фамилия</label>
              <input
                v-model="form.surname"
                placeholder="Введите фамилию"
                :class="['form-control', submitted && !form.surname ? 'is-invalid' : null]"
              >
              <small v-if="submitted && !form.surname" class="text-danger">Поле не может быть пустым</small>
            </div>
            <div class="form-group">
              <label class="d-block">Телефон</label>
              <input
                v-model="form.phone"
                placeholder="Введите телефон"
                :class="['form-control', submitted && !form.phone ? 'is-invalid' : null]"
              >
              <small v-if="submitted && !form.phone" class="text-danger">Поле не может быть пустым</small>
            </div>
            <div class="form-group">
              <label class="d-block">Электронная почта</label>
              <input
                v-model="form.username"
                placeholder="Введите почту"
                type="email"
                :class="['form-control', submitted && !form.username ? 'is-invalid' : null]"
              >
              <small v-if="submitted && !form.username" class="text-danger">Поле не может быть пустым</small>
            </div>
            <div class="form-group">
              <label class="d-block">Пароль</label>
              <div
                role="group"
                :class="['input-group input-group-merge', submitted && !form.password ? 'is-invalid' : null]"
              >
                <input
                  v-model="form.password"
                  :type="passwordFieldType"
                  placeholder="············"
                  autocomplete="off"
                  :class="['form-control', submitted && !form.password ? 'is-invalid' : null]"
                >
                <div class="input-group-append">
                  <div class="input-group-text">
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </div>
                </div>
              </div>

              <small v-if="submitted && !form.password" class="text-danger">Поле не может быть пустым</small>
            </div>
            <div class="custom-control custom-checkbox">
              <input
                id="terms"
                v-model="form.terms"
                type="checkbox"
                :class="['custom-control-input', submitted && !form.terms ? 'is-invalid' : null]"
              >
              <label class="custom-control-label" for="terms">
                Я согласен с <a href="#" target="_self">политикой компании и условиями</a>
              </label>
            </div>
            <b-button class="mt-3" variant="primary" block type="submit" :disabled="disabled" @click="register">
              Зарегистрироваться
            </b-button>
          </div>

          <p class="text-center mt-2">
            <span>Уже есть аккаунт?</span>
            <b-link :to="{name:'auth-login'}"><span>&nbsp;Войти</span></b-link>
          </p>
        </div>
      </div>
      <!-- /Register-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue';

import {
  BLink,
  BButton,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    VuexyLogo,
    BImg,
    BLink,
    BButton,
    BCardText,
    BCardTitle,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        terms: false,
        name: null,
        surname: null,
        phone: null,
        username: null,
        password: null,
      },
      disabled: false,
      submitted: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    async register() {
      this.submitted = true;
      this.disabled = true;

      try {
        const response = (await this.$api.auth.register(this.form)).data;
        localStorage.setItem('accessToken', response.token);
        localStorage.setItem('userData', JSON.stringify(response.userData));
        this.$ability.update(response.userData.ability);
        this.$router.replace({ name: 'remains' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Добро пожаловать ${response.userData.full_name || response.userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Вы успешно вошли в личный кабинет. Теперь вы можете начать исследовать!',
                },
              });
            });
      } catch (e) {
        const errors = e.response.data.details;
        let message = 'Произошла ошибка';
        if (errors.username) {
          message = 'Пользователь с такой почтой уже существует';
        }
        this.$toast.error(message, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      if (!this.name) {
        this.disabled = false;
        return true;
      }

      return true;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
